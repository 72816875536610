.maincontainer {
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 35px;
  border: 1px solid #d9dadb;
  box-shadow: 10px 10px 5px rgba(82, 63, 105, 0.09);
  max-width: 1000px;
  overflow: auto;
  background-color: white;
  padding: 2%;
  padding-left: 4%;
  padding-right: 4%;
}

.submitbutton {
  background-color: rgb(69, 160, 212);
  width: 120px;
  border-radius: 8px;
  border: none;
  color: white;
  padding: 9px;
  transition: 2s;
  margin: 0 auto;
  margin-bottom: 40px;
  margin-top: 20px;
  text-align: center;
}
.submitbutton:hover {
  box-shadow: 5px 5px 2px rgb(179, 176, 176);
  background-color: blue;
}

/*.....................Create accoun css (Sign up)...............................................................*/
.sign_form {
  width: 49%;
  float: left;
  padding: 4%;
}

.main-form {
  display: flex;
  flex-direction: column;
}
.gg {
  overflow: auto;
}

.submitbuttonup {
  background-color: rgb(69, 160, 212);
  width: 120px;
  border-radius: 8px;
  border: none;
  color: white;
  padding: 9px;
  transition: 2s;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
.submitbuttonup:hover {
  box-shadow: 5px 5px 2px rgb(179, 176, 176);
  background-color: blue;
}

.p_img {
  height: 110px;
  width: 110px;
  border-radius: 50%;
  background-color: grey;
}
.forflex {
  width: 100%;
  display: flex;
  justify-content: center;
}
.uploaddiv {
  position: absolute;
  margin: 0 auto;
  margin-top: -10px;
}
.upload {
  position: absolute;
  bottom: 0;
  right: 5px;
}

.reference {
  display: none;
}
/*input[type="date"]::before {
  color: lightgrey;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}
input[type="date"] {
  text-align: right;
}*/

.f_img_cont {
  width: 100%;
  display: flex;
  justify-content: center;
}

.f_img {
  margin-top: 25px;
  width: 250px;
}
