* {
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
a {
  text-decoration: none;
}
body {
  margin: 0px;
  font-size: 14px;
  scrollbar-gutter: stable;
  scrollbar-color: rgb(194, 194, 194) transparent;
  scrollbar-width: thin;
}

/*..............Welcome Page.................................................*/
.welcome_container {
  padding: 2%;
  overflow: auto;
  max-width: 1500px;
  margin: 0 auto;
  max-height: 100%;
}

.mar_holder {
  width: 100%;
  overflow: auto;
}
.sign_in {
  border-radius: 6px;
  width: 140px;
  padding: 8px;
  border: 1px solid grey;
  float: right;
  text-align: center;
  margin-bottom: 80px;
  font-size: 16px;
  transition: 2s;
  background-color: white;
}

.sign_in:hover {
  background-color: blue;
  color: white;
  border: none;
  font-weight: bold;
}

.sign_text {
  float: left;
  width: 94%;
  margin-left: 5%;
  display: flex;
  justify-content: space-between;
}
.design1 {
  font-size: 50px;
  font-weight: 600;
}
.design2 {
  font-size: 90px;
  font-weight: 900;
  color: rgb(63, 169, 211);
}

.design3 {
  font-size: 27px;
  margin-bottom: 50px;
}
.edu_img {
  width: 600px;
  margin: 0 auto;
}

.sign_up {
  border-radius: 6px;
  width: 140px;
  padding: 8px;
  text-align: center;
  color: white;
  background-color: rgb(96, 192, 230);
  font-size: 16px;
  transition: 2s;
}
.sign_up:hover {
  color: rgb(18, 131, 197);
  border: 1px solid grey;
  background-color: white;
  font-weight: bold;
}

/*.............Sign In Page..................................................*/
.main_container {
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 60px;
  border: 1px solid #d9dadb;
  box-shadow: 10px 10px 5px rgba(82, 63, 105, 0.09);
  background-color: rgb(96, 192, 230);
  max-width: 1000px;
  overflow: auto;
}
.logo {
  width: 60px;
  height: 60px;
}

.side_image {
  width: 30%;
  overflow: auto;
  float: left;
  /* min-height: 100%;?*/
}

.side {
  width: 70%;
  overflow: auto;
  float: left;
  background-color: white;
}

.title {
  font-size: 25px;
  text-align: center;
  margin: 9px;
  margin-top: 30px;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: 600;
}

.instruct {
  text-align: center;
  font-size: 14px;
  margin-bottom: 60px;
  color: rgb(63, 62, 62);
}

.form_content {
  display: flex;
  margin: 0 auto;
  width: 90%;
  flex-direction: column;
  width: 400px;
}

.forgot {
  color: rgb(18, 131, 197);
  font-size: 14px;
}

.form_input {
  border: 1px groove rgb(163, 163, 163);
  border-radius: 4px;
  margin-bottom: 25px;
  padding: 8px;
  font-size: 13px;
  width: 100%;
  background-color: white;
}
.form_input:focus {
  border: 2px groove rgb(18, 131, 197);
}
input {
  outline: none;
}

.logo_cont {
  padding: 10px;
}

.holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submit {
  background-color: rgb(69, 160, 212);
  width: 120px;
  border-radius: 8px;
  border: none;
  color: white;
  padding: 9px;
  transition: 2s;
}
.submit:hover {
  box-shadow: 5px 5px 2px rgb(179, 176, 176);
  background-color: blue;
}
.pass {
  font-size: 14px;
}
#agree {
  font-size: 11 px;
}
p {
  margin: 5px;
}
.welcome {
  text-align: center;
  color: white;
  font-size: 35px;
  font-weight: 600;
  margin: 0 auto;
  width: 90%;
  font-family: ubuntu;
}

.showbreak {
  display: flex;
  align-items: baseline;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 30px;
}
.break {
  border-bottom: 1px solid grey;
  width: 48%;
}

.gcont {
  width: 100%;
  display: flex;
  justify-content: center;
}

.google {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.gcontain {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid grey;
  padding: 5px;
}

.extra {
  margin-bottom: 50px;
}

.onoff {
  position: absolute;
  right: 30px;
  top: 4px;
}

.formwidth {
  position: relative;
}

/*..............Home Page.................................................*/
.home_whole {
  overflow: auto;
  margin: 0 auto;
  height: calc(100% - 38px);
}
.Home_container {
  padding: 2px;
  max-width: 1400px;
  margin: 0 auto;
  overflow: auto;
}

.Header_container {
  overflow: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
  padding-top: 20px;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 5px 5px 3px rgba(82, 63, 105, 0.09);
  max-width: 1400px;
  height: 68px;
  margin: 0 auto;
}

.Home_logo {
  width: 40px;
  height: 40px;
}

.contact_pic {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.Home_logocont {
  width: 27%;
}

.nav_holder {
  display: flex;
  width: 45%;
  justify-content: center;
}

.nav {
  color: black;
}

.nav:hover {
  color: green;
}

.searchcont {
  width: 25%;
  display: flex;
  justify-content: flex-end;
}

.search_input {
  width: 100%;
  padding: 8px;
  border-radius: 18px;
  padding-left: 10px;
  border: 1px solid #d9dadb;
  box-shadow: 2px 2px 2px rgba(82, 63, 105, 0.09);
  text-align: center;
  transition: 1.5s;
  color: black;
}
.search_input:focus {
  text-align: left;
  color: black;
}
.search_input::placeholder {
  transition: 2s;
}
.in_holder {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.connect {
  width: 26%;
  float: left;
  text-align: center;
  background-color: white;
  border-radius: 15px;
  margin-top: 15px;
  margin-left: 1.5%;
  padding: 10px;
  padding-top: 20px;
  border: 1px solid #d9dadb;
  box-shadow: 2px 2px 2px rgba(82, 63, 105, 0.09);
}

.Que {
  width: 45%;
  float: left;
  text-align: center;
  margin-top: 15px;
  /*min-height: 85vh;*/
  margin-left: 1%;
  margin-right: 1%;
}
.Que_sub {
  border-radius: 15px;
  padding: 15px;
  padding-top: 20px;
  margin-top: 15px;
  background-color: white;
  /*min-height: 78vh;*/
  border: 1px solid #d9dadb;
  box-shadow: 2px 2px 2px rgba(82, 63, 105, 0.09);
}
.Noti {
  width: 24%;
  float: left;
  text-align: center;
  background-color: transparent;
  border-radius: 15px;
  margin-top: 15px;
  /* min-height: 85vh;*/
  margin-right: 1.5%;
  border: 1px solid #d9dadb;
  box-shadow: 2px 2px 2px rgba(82, 63, 105, 0.09);
  padding-top: 20px;
}

.notify {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 153px);
}

.home_title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  background-color: transparent;
}

.connect_cit {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(248, 246, 246);
  font-weight: bold;
  padding-bottom: 8px;
  color: #d9dadb;
}

.cm {
  color: green;
}

.wm {
  color: orange;
}

.c_pic {
  width: 17%;
  float: left;
  margin-right: 2%;
  overflow: hidden;
  text-align: left;
}
.c_time {
  width: 20%;
  float: left;
  overflow: hidden;
  text-align: left;
}
.c_text {
  width: 59%;
  float: left;
  margin-left: 2%;
  overflow: hidden;
  text-align: left;
  font-size: 15px;
}

.c_m {
  font-size: 13px;
  font-weight: 500;
}

.alert {
  text-align: center;
  background-color: rgb(18, 131, 197);
  color: white;
  border-radius: 50%;
  width: 15px;
  font-size: 12px;
  margin: 0 auto;
  font-weight: 500;
}
.time {
  text-align: center;
  font-size: 12.5px;
}
.time2 {
  width: 28%;
  float: left;
  text-align: left;
}
.contact_container {
  font-weight: 600;
  overflow: auto;
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.contact_container:hover {
  background-color: rgb(243, 239, 239);
}
.search_holder {
  width: 100%;
}

.search_input2 {
  width: 70%;
  color: black;
  padding: 8px;
  border-radius: 10px;
  padding-left: 10px;
  border: 1px solid #d9dadb;
  box-shadow: 2px 2px 2px rgba(82, 63, 105, 0.09);
  font-size: 14px;
}

/*....................Feed.........................................*/
.feed_name {
  text-align: left;
  font-size: 19px;
  font-weight: bold;
  margin-left: 18px;
}

.feed_content {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 10px;
}

.feed_pic {
  max-width: 150px;
}

.reaction {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(236, 234, 234);
  padding: 10px;
  color: grey;
}

.comment_cont {
  padding: 10px;
  color: rgb(87, 86, 86);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(236, 234, 234);
}
.comment_input {
  width: 65%;
  padding: 13px;
  border-radius: 18px;
  padding-left: 10px;
  border: 1px solid #bababb;
  box-shadow: 2px 2px 2px rgba(82, 63, 105, 0.09);
  transition: 1.5s;
  color: black;
}

.vote {
  width: 15px;
  text-align: center;
}

.vote_amt {
  display: flex;
  justify-content: center;
}

.reply {
  width: 54px;
}
/*....................Feed End.......................................*/

.contact {
  padding: 2px;
  overflow-x: hidden;
  overflow-x: auto;
  height: calc(100vh - 191px);
}

::-webkit-scrollbar {
  width: 10px;
  height: 40px;
}

.contact::-webkit-scrollbar {
  width: 10px;
  height: 40px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(194, 194, 194);
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

.user_pic {
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.feed_head {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.suggest_container {
  font-weight: 600;
  overflow: auto;
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.s_text {
  width: 42%;
  float: left;
  margin-left: 2%;
  overflow: hidden;
  text-align: left;
  font-size: 15px;
}

.s_incre {
  width: 34%;
  float: left;
  margin-left: 2%;
  font-weight: 300;
  font-size: 12px;
}
.s_add {
  width: 48.5%;
  float: left;
  background-color: rgb(80, 182, 241);
  border-radius: 8px;
  color: white;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s;
  cursor: pointer;
}

.s_reject {
  width: 48.5%;
  overflow: hidden;
  float: left;
  margin-left: 3%;
  color: black;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px groove grey;
  border-radius: 8px;
  transition: 1s;
  cursor: pointer;
}

.s_add:hover {
  background-color: blue;
  box-shadow: 2px 4px rgb(223, 221, 221);
}

.s_reject:hover {
  color: grey;
  box-shadow: 2px 4px rgb(223, 221, 221);
  border: 1px groove grey;
}

.noti_container1 {
  font-weight: 600;
  overflow: auto;
  display: flex;
  align-items: center;
  background-color: rgb(80, 182, 241);
  color: white;
  border-bottom: 1px solid white;
}
.n_time1 {
  width: 20%;
  float: left;
  overflow: hidden;
  text-align: left;
  color: black;
  background-color: white;
  border-radius: 8px;
  font-size: 11px;
  text-align: center;
}
.noti_container2 {
  font-weight: 600;
  overflow: auto;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(236, 234, 234);
}

.nb_time {
  font-size: 12px;
  text-align: left;
}

.n_pic {
  width: 16%;
  overflow: hidden;
  /*border-radius: 50%;
  background-color: white;*/
}

.n_text {
  width: 56%;
  text-align: left;
  float: left;
}

.wlc_img {
  margin-top: 25px;
  width: 230px;
}
